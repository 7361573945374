import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './JobTrackerPage.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import fakedata from './fakedata.json';


const JobTracker = () => {
  const [jobs, setJobs] = useState([]);
  const [sortKey, setSortKey] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [editCell, setEditCell] = useState({ row: null, column: null });
  const [formData, setFormData] = useState({
    companyName: '',
    jobTitle: '',
    dateApplied: null,
    status: '',
    notes: '',
    jobID: ''
  });
  const [searchQuery, setSearchQuery] = useState('');


    const navigate = useNavigate();

    const goToUserProfile = () => {
      navigate('/userprofile'); // Adjust this path to match your route for the UserProfile page
    };
    const goToResume = () => {
      navigate('/resume'); // Adjust this path to match your route for the UserProfile page
    };


    useEffect(() => {
      const fetchUserJobs = async () => {
        try {
          const userId = sessionStorage.getItem('userId');
          console.log('Fetched userId from session storage:', userId);
  
          if (!userId) {
            console.error('Error: User ID not found in session storage.');
            return;
          }
  
          const params = new URLSearchParams();
          params.append('ObjectId', userId);
  
          console.log('Sending API request with parameters:', params.toString());
          const response = await axios.post('/UP/getAttributes.jsp', params);
          const jobIds = response.data.Attributes.JobID;
  
          console.log('Job IDs:', jobIds);
  
          const jobDetails = [];
          for (const jobId of jobIds) {
            try {
              const jobParams = new URLSearchParams();
              jobParams.append('ObjectId', jobId);
              
              console.log(`Fetching details for Job ID: ${jobId}`);
              const jobResponse = await axios.post('/UP/getAttributes.jsp', jobParams);
              console.log(`Job details for ${jobId}:`, jobResponse.data);
              jobDetails.push(jobResponse.data);

            } catch (error) {
              console.error(`Error fetching job details for Job ID ${jobId}:`, error);
            }
          }
  
          const jobData = jobDetails.map(job => ({
            companyName: job.Attributes.Company || 'Unknown',
            jobTitle: job.Attributes.Title || 'Unknown',
            dateApplied: job.Attributes.AppliedDate
            ? new Date(job.Attributes.AppliedDate).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              })
            : 'Unknown',
            status: job.Attributes.Status || 'Unknown',
            notes: job.Attributes.Note || '',
            jobID: job.ObjectId || '',
          }));
  
          console.log('Mapped job details: ', jobData);
          setJobs(jobData);
        } catch (error) {
          console.error('Error fetching jobs:', error);
        }
      };
  
      fetchUserJobs();
    }, []);
    
    
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dateApplied: date
    });
  };

  const handleDateInput = (e) => {
    let value = e.target.value;
    
    if (e.nativeEvent.inputType === "insertText") {
      value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
      if (value.length >= 2) value = value.slice(0, 2) + '/' + value.slice(2);
      if (value.length >= 5) value = value.slice(0, 5) + '/' + value.slice(5, 9);
    }
  
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      if (value.length === 3) {
        value = value.slice(0, 1);
      } else if (value.length === 4) {
        value = value.slice(0, 3);
      }
    }
    e.target.value = value;
  };

  const addJob = (e) => {
    e.preventDefault();
    const isFormValid = formData.companyName && formData.jobTitle && formData.dateApplied && formData.status;

    if (!isFormValid) {
      alert('Please fill in all required fields.');
      return;
    }

    const updatedJobs = [
      {
        ...formData,
        dateApplied: formData.dateApplied ? formData.dateApplied.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '',
      },
      ...jobs
    ];

    updatedJobs.sort((a, b) => new Date(a.dateApplied) - new Date(b.dateApplied));
    setJobs(updatedJobs);

    setFormData({
      companyName: '',
      jobTitle: '',
      dateApplied: null,
      status: '',
      notes: '',
      jobID: ''
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCellClick = (row, column) => {
    setEditCell({ row, column });
  };

  const handleCellDateChange = (date, rowIndex) => {
    const formattedDate = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit',year: 'numeric'});
  
    const updatedJobs = jobs.map((job, index) =>
      index === rowIndex ? { ...job, dateApplied: formattedDate } : job
    );
  
    setJobs(updatedJobs);
    updateJobs(updatedJobs);
    setEditCell({ row: null, column: null });
  };
  
  const handleCellChange = (e, rowIndex, column) => {
    const updatedJobs = jobs.map((job, index) =>
      index === rowIndex ? { ...job, [column]: e.target.value } : job
    );
    setJobs(updatedJobs);
    updateJobs(updatedJobs);
  };

  const handleBlur = () => {
    setEditCell({ row: null, column: null });
  };

  const sortJobs = (key) => {
    let sortedJobs = [...jobs];
    sortedJobs.sort((a, b) => {
      if (a[key] < b[key]) return sortDirection === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
    setJobs(sortedJobs);
    setSortKey(key);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const getSortIcon = (column) => {
    if (sortKey !== column) return '';
    return sortDirection === 'asc' ? '^' : 'v';
  };

  const isFormValid = formData.companyName && formData.jobTitle && formData.dateApplied && formData.status;

  const filteredJobs = jobs.filter(job => 
    job.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.jobTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.status.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const updateJobs = async (updatedJobs) => {
    try {
      await axios.put('/api/updateJob', updatedJobs); //REPLACE WITH REAL API CALL
      console.log('Jobs updated successfully');
    } catch (error) {
      console.error('Error updating jobs:', error);
    }
  };
  

  return (
    <div className="App">
      <div className="navbar">
          <div className="navbar-left">
            <button className="nav-button" onClick={goToResume}>
                <i className="fas fa-file"></i>
              </button>
              <button className="nav-button" onClick={goToUserProfile}>
                <i class="fa-solid fa-user"></i>
              </button>
              <button className="nav-button">
                <i class="fa-solid fa-home"></i>
              </button>
          </div>
          <div className="navbar-center">
              <h1>ResuFit AI</h1>
          </div>
          <div className="navbar-right">

          </div>
      </div>
      <div className="title">
        <h3>Application List</h3>
      </div>
      <input 
        type="text" 
        className="search-bar"
        placeholder="Search by company, job title, or status"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <div className="content">
              {/* Search Bar */}
      
        <table className="job-table">
          <thead>
            <tr className="job-table-header">
              <th onClick={() => sortJobs('companyName')}>
                Company Name {getSortIcon('companyName')}
              </th>
              <th onClick={() => sortJobs('jobTitle')}>
                Job Title {getSortIcon('jobTitle')}
              </th>
              <th onClick={() => sortJobs('dateApplied')}>
                Date Applied {getSortIcon('dateApplied')}
              </th>
              <th onClick={() => sortJobs('status')}>
                Status {getSortIcon('status')}
              </th>
              <th>Notes</th>
            </tr>
            <tr>
              <th><input type="text" name="companyName" value={formData.companyName} onChange={handleInputChange} placeholder="Company Name" /></th>
              <th><input type="text" name="jobTitle" value={formData.jobTitle} onChange={handleInputChange} placeholder="Job Title" /></th>
              <th><DatePicker selected={formData.dateApplied} onChange={handleDateChange} onChangeRaw={handleDateInput} dateFormat="MM/dd/yyyy" placeholderText="MM/dd/yyyy" /></th>
              <th>
                <select name="status" value={formData.status} onChange={handleInputChange} className="status-select">
                  <option value="" disabled hidden>Select Status</option>
                  <option value="Applied">Applied</option>
                  <option value="Interviewing">Interviewing</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </th>
              <th><input type="text" name="notes" value={formData.notes} onChange={handleInputChange} placeholder="Notes" /></th>
              <th><button className={`add-button ${isFormValid ? 'show' : ''}`} onClick={addJob} disabled={!isFormValid}>Add</button></th>
            </tr>
          </thead>
          <tbody>
            {filteredJobs.map((job, rowIndex) => (
              <tr key={rowIndex} className="fade-in-row" style={{ animationDelay: `${(filteredJobs.length - rowIndex) * 0.1}s` }}>
                {Object.keys(job)
                  .filter((column) => column !== 'jobID') // Exclude 'jobID' from rendering
                  .map((column) => (
                    <td key={column} onClick={() => handleCellClick(rowIndex, column)}>
                      {editCell.row === rowIndex && editCell.column === column ? (
                        column === 'dateApplied' ? (
                          <DatePicker
                            selected={new Date(job[column])}
                            onChange={(date) => handleCellDateChange(date, rowIndex)}
                            dateFormat="MM-dd-yyyy"
                            onBlur={handleBlur}
                            autoFocus
                          />
                        ) : column === 'status' ? (
                          <select
                            value={job[column]}
                            onChange={(e) => handleCellChange(e, rowIndex, column)}
                            onBlur={handleBlur}
                            autoFocus
                          >
                            <option value="Applied">Applied</option>
                            <option value="Interviewing">Interviewing</option>
                            <option value="Rejected">Rejected</option>
                          </select>
                        ) : (
                          <input
                            type="text"
                            value={job[column]}
                            onChange={(e) => handleCellChange(e, rowIndex, column)}
                            onBlur={handleBlur}
                            autoFocus
                          />
                        )
                      ) : (
                        job[column]
                      )}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobTracker;
