import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TestPage = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchUserJobs = async () => {
      try {
        const userId = sessionStorage.getItem('userId');
        console.log('Fetched userId from session storage:', userId);

        if (!userId) {
          console.error('Error: User ID not found in session storage.');
          return;
        }

        const params = new URLSearchParams();
        params.append('ObjectId', userId);

        console.log('Sending API request with parameters:', params.toString());
        const response = await axios.post('/UP/getAttributes.jsp', params);
        const jobIds = response.data.Attributes.JobID;

        console.log('Job IDs:', jobIds);

        const jobDetails = [];
        for (const jobId of jobIds) {
          try {
            const jobParams = new URLSearchParams();
            jobParams.append('ObjectId', jobId);

            console.log(`Fetching details for Job ID: ${jobId}`);
            const jobResponse = await axios.post('/UP/getAttributes.jsp', jobParams);
            console.log(`Job details for ${jobId}:`, jobResponse.data);
            jobDetails.push(jobResponse.data);
          } catch (error) {
            console.error(`Error fetching job details for Job ID ${jobId}:`, error);
          }
        }

        const jobData = jobDetails.map(job => ({
          companyName: job.Attributes.Company || 'Unknown',
          jobTitle: job.Attributes.Title || 'Unknown',
          dateApplied: job.Attributes.AppliedDate || 'Unknown',
          status: job.Attributes.Status || 'Unknown',
        }));

        console.log('Mapped job details: ', jobData);
        setJobs(jobData);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchUserJobs();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h1>Job Details</h1>
      {jobs.length > 0 ? (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ccc', padding: '10px' }}>Company</th>
              <th style={{ border: '1px solid #ccc', padding: '10px' }}>Job Title</th>
              <th style={{ border: '1px solid #ccc', padding: '10px' }}>Date Applied</th>
              <th style={{ border: '1px solid #ccc', padding: '10px' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((job, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ccc', padding: '10px' }}>{job.companyName}</td>
                <td style={{ border: '1px solid #ccc', padding: '10px' }}>{job.jobTitle}</td>
                <td style={{ border: '1px solid #ccc', padding: '10px' }}>{job.dateApplied}</td>
                <td style={{ border: '1px solid #ccc', padding: '10px' }}>{job.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No job details available.</p>
      )}
    </div>
  );
};

export default TestPage;
