import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import axios from 'axios';

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsButtonEnabled(username.trim() !== '' && password.trim() !== '');
  }, [username, password]);

  const setUserId = (userId) => {
    sessionStorage.setItem('userId', userId);
    console.log('UserId stored in sessionStorage:', userId);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      if (await submitRequest(username, password, setUserId)){
        navigate('/jobtracker');
      };
    } catch (error) {
      console.error('Error during login:', error.message);
      setError('Login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const response = await axios.post('/api/google-login', { token: credentialResponse.credential });
      if (response.data.status === 'success') {
        setUserId(response.data.ObjectId);
        console.log('Google login successful:', response.data);
        navigate('/JobTrackerPage');
      } else {
        setError('Google login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during Google login:', error);
      setError('An error occurred during Google login.');
    }
  };

  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <div className="App">
        <div className="navbar">
          <h1>ResuFit AI</h1>
        </div>
        <div className="login_container">
          <h3>Login</h3>
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={handleLogin}>
            <InputField label="Username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            <InputField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button className={`login_button ${isButtonEnabled ? 'show' : ''}`} disabled={!isButtonEnabled || isLoading}>
              {isLoading ? 'Loading...' : 'Login'}
            </button>
          </form>
          <div style={{ marginTop: '1rem' }}>
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={() => setError('Google login failed. Please try again.')}
            />
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

const InputField = ({ label, type, value, onChange }) => (
  <div style={{ marginBottom: '1rem' }}>
    <label>{label}</label>
    <input type={type} value={value} onChange={onChange} style={{ width: '100%', padding: '0.5rem' }} required />
  </div>
);

async function submitRequest(uname, passwd, setUserId) {
  const params = new URLSearchParams();
  params.append('Uid', uname);
  params.append('Password', passwd);

  try {
    const response = await axios.post('/UP/checklogin_up.jsp', params, { withCredentials: true });
    if (response.data.status === 'success') {
      console.log('Login successful:', response.data);
      setUserId(response.data.userId);
      return true;
    } else {
      throw new Error(response.data.message || 'Login failed');
    }
  } catch (error) {
    throw error;
  }
}

export default LoginPage;
