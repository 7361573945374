// ResumePage.js
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import uploadpic from './uploadimg.png';
import { pdfjs, Document, Page } from 'react-pdf';
import './ResumePage.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs';


function ResumePage() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [numPages, setNumPages] = useState();
  const fileInputRef = useRef(null);

  // Save the file in sessionStorage
  const saveFileToSessionStorage = (fileData, fileName) => {
    sessionStorage.setItem('uploadedFile', fileData); // Base64 string of the file
    sessionStorage.setItem('uploadedFileName', fileName); // File name
  };

  // Retrieve the file from sessionStorage
  const retrieveFileFromSessionStorage = () => {
    const storedFile = sessionStorage.getItem('uploadedFile');
    const storedFileName = sessionStorage.getItem('uploadedFileName');
    if (storedFile && storedFileName) {
      // Convert base64 to blob
      const byteCharacters = atob(storedFile.split(',')[1]);
      const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      setFile(blob); // Set the blob as the file
      setFileName(storedFileName); // File name
    }
  };
  

  // Handle file upload with axios
  const handleFileUpload = async (file) => {
    if (file.type !== 'application/pdf') {
      alert('Please upload a PDF file.');
      return;
    }

    const formData = new FormData();
    formData.append('pdfFile', file);
    setFileName(file.name);

    try {
      // Upload file to server using axios
      await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Convert file to base64 and save it in state and sessionStorage
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64File = reader.result;
        setFile(base64File);
        
        // Save the file to sessionStorage
        saveFileToSessionStorage(base64File, file.name);
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // Handle file change
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      handleFileUpload(uploadedFile);
    } else {
      alert('Please upload a PDF file.');
    }
  };

  // Handle drag and drop
  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      handleFileUpload(file);
    } else {
      alert('Please upload a PDF file.');
    }
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleNextStep = () => {
    navigate('/JobDesc', { state: { fileName } });
  };

  // Retrieve the file from sessionStorage when the component loads
  useEffect(() => {
    retrieveFileFromSessionStorage();
  }, []);

  return (
    <div className="App">
      <div className="navbar">
       <div className="navbar-left" onClick={() => navigate(-1)}>
          <button className="nav-button">
              <i className="fas fa-arrow-left"></i>
          </button>
        </div>
        <div className="navbar-center">
            <h1>ResuFit AI</h1>
        </div>
        <div className="navbar-right">

        </div>
      </div>

      <div className="resume_container">
        {file ? (
          <div className="pdf-container">
            <div
              className="pdf-section"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={() => fileInputRef.current && fileInputRef.current.click()}
            >
              <Document
                file={file}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                loading={null} // Disabling the default loading indicator
              >
                {Array.from({ length: numPages }, (v, i) => (
                  <Page
                    key={`page_${i + 1}`}
                    pageNumber={i + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                ))}
              </Document>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="drop_box">
              <div
                className="upload-section"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => fileInputRef.current && fileInputRef.current.click()}
              >
                <img src={uploadpic} alt="Upload Icon" />
                <label className="upload-label">
                  {fileName
                    ? fileName.split('').map((char, index) => (
                        <span
                          key={index}
                          className="letter"
                          style={{ animationDelay: `${index * 0.1}s` }}
                        >
                          {char === ' ' ? '\u00A0' : char}
                        </span>
                      ))
                    : 'Submit PDF Resume'.split('').map((char, index) => (
                        <span
                          key={index}
                          className="letter"
                          style={{ animationDelay: `${index * 0.05}s` }}
                        >
                          {char === ' ' ? '\u00A0' : char}
                        </span>
                      ))}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <input
        type="file"
        onChange={handleFileChange}
        accept="application/pdf"
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      
      <button
        className={`next-step-button ${file ? 'show' : ''}`}
        onClick={handleNextStep}
        disabled={!file}
      >
        Next Step →
      </button>
    </div>
  );
}

export default ResumePage;
