import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './UserProfilePage.css';

function UserProfile() {
    // Define state to hold user data
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        // Fetch user profile from the database
        const fetchUserProfile = async () => {
            try {
                // Get the user ID from session storage
                const userId = sessionStorage.getItem('userId');
                console.log('userId: ', userId);
                if (!userId) {
                    console.error('User ID not found in session storage.');
                    return;
                }
    
                // First API call to fetch user profile
                const params = new URLSearchParams();
                params.append('ObjectId', userId);
                const response = await axios.post('/UP/getAttributes.jsp', params);
    
                if (response.data.status === 'success') {
                    console.log('Initial data: ', response.data);
                    const attributes = response.data.Attributes;
    
                    // Extract SkillID from the response
                    const skillID = attributes.SkillID;
                    if (!skillID) {
                        console.error('SkillID not found in the response.');
                        return;
                    }
                    console.log("SkillID: ", skillID);
    
                    // Second API call to fetch skills based on SkillID
                    const skillParams = new URLSearchParams();
                    skillParams.append('ObjectId', skillID);
                    const skillResponse = await axios.post('/UP/getAttributes.jsp', skillParams);
    
                    if (skillResponse.data.status === 'success') {
                        console.log('Skill data: ', skillResponse.data);
    
                        // Parse skills and other data from the second API response
                        const skillAttributes = skillResponse.data.Attributes;
    
                        // Combine data from both API calls
                        const formattedUserData = {
                            name: response.data.ObjectName || 'Unknown User',
                            profilePicture: 'https://via.placeholder.com/150', // Default placeholder
                            personalInfo: {
                                email: response.data.ObjectName || 'No Email',
                                location: attributes.Location || 'Unknown',
                                phone: attributes.Phone || 'Unknown',
                            },
                            skills: skillAttributes.Abilities || [], // Use abilities from the second API call
                            education: skillAttributes.Education.map((edu) => {
                                const parts = edu.split(", ");
                                const degree = parts.find((p) => p.includes("degree:")).split(": ")[1];
                                const university = parts.find((p) => p.includes("university:")).split(": ")[1];
                                const year = parts.find((p) => p.includes("year:")).split(": ")[1];
                                return { degree, university, year };
                            }) || [],
                            projects: skillAttributes.Projects.map((proj) => {
                                const parts = proj.split(", ");
                                const title = parts.find((p) => p.includes("title:")).split(": ")[1];
                                const description = parts.find((p) => p.includes("description:")).split(": ")[1];
                                const date = parts.find((p) => p.includes("date:")).split(": ")[1];
                                return { title, description, date };
                            }) || [],
                            experience: skillAttributes.Experience.map((exp) => {
                                const parts = exp.split(", ");
                                const company = parts.find((p) => p.includes("company:")).split(": ")[1];
                                const title = parts.find((p) => p.includes("title:")).split(": ")[1];
                                const description = parts.find((p) => p.includes("description:")).split(": ")[1];
                                const date = parts.find((p) => p.includes("date:")).split(": ")[1];
                                return { company, title, description, date };
                            }) || [],
                            interests: attributes.Interests || [],
                        };
    
                        setUserData(formattedUserData); // Set the combined data in state
                    } else {
                        console.error('Failed to fetch skills:', skillResponse.data);
                    }
                } else {
                    console.error('Failed to fetch user data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        fetchUserProfile();
    }, []);
    
    
/*
    useEffect(() => {
        // Replace the axios call with fake data
        const fakeUserData = {
            name: 'John Doe',
            profilePicture: 'https://via.placeholder.com/150', // Placeholder image
            personalInfo: {
                email: 'johndoe@example.com',
                phone: '+1234567890',
                location: 'New York, USA',
            },
            skills: ['JavaScript', 'React', 'Node.js', 'CSS', 'HTML'],
            education: [
                { degree: 'B.Sc. in Computer Science', university: 'XYZ University', year: '2020' },
                { degree: 'M.Sc. in Software Engineering', university: 'ABC University', year: '2022' },
            ],
            projects: [
                { title: 'Project 1', description: 'A web app built with React and Node.js.' },
                { title: 'Project 2', description: 'A full-stack e-commerce application.' },
            ],
        };

        setUserData(fakeUserData);
    }, []);
    // If data is still loading, render a loading message
    if (!userData) {
        return <div>Loading...</div>;
    }*/

        return (
            <div className="App">
                <div className="navbar">
                    <div className="navbar-left" onClick={() => navigate(-1)}>
                        <button className="nav-button">
                            <i className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                    <h1>ResuFit AI</h1>
                    <div className="navbar-right">
                    </div>
                </div>
                
                {/* Profile Section */}
                <div className="profile-container">
                    {/* Check if userData is loaded */}
                    {userData ? (
                        <>
                            {/* Profile Header: Profile Picture and Name */}
                            <div className="profile-header">
                                <img 
                                    src={userData.profilePicture || 'https://via.placeholder.com/150'} 
                                    alt="Profile" 
                                    className="profile-pic" 
                                />
                                <h2>{userData.name || 'Unknown User'}</h2>
                            </div>
        
                            {/* Two columns: Left for Skills/Experiences, Right for Personal Info */}
                            <div className="profile-body">
                                {/* Left Column - Skills and Experience */}
                                <div className="left-column">
                                    <h3>Skills</h3>
                                    <ul>
                                        {userData.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
        
                                    <h3>Education</h3>
                                    <ul>
                                        {userData.education.map((edu, index) => (
                                            <li key={index}>
                                                <strong>{edu.degree}</strong> - {edu.university} ({edu.year})
                                            </li>
                                        ))}
                                    </ul>
        
                                    <h3>Projects</h3>
                                    <ul>
                                        {userData.projects.map((project, index) => (
                                            <li key={index}>
                                                <strong>{project.title}</strong>: {project.description}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
        
                                {/* Right Column - Personal Information */}
                                <div className="right-column">
                                    <h3>Personal Information</h3>
                                    <ul>
                                        <li><strong>Email:</strong> {userData.personalInfo.email}</li>
                                        <li><strong>Phone:</strong> {userData.personalInfo.phone}</li>
                                        <li><strong>Location:</strong> {userData.personalInfo.location}</li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    ) : (
                        // Loading state
                        <div>Loading...</div>
                    )}
                </div>
            </div>
        );
        
}

export default UserProfile;
